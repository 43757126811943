@import 'common-flagship_tasting_room';
@import "tasting-reservation";
@import 'common-wineclub';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn.m-alt {
	border: none;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			background: var(--button_alt-background_color, $color-button_alt-background);
			color: var(--button_alt-text_color, $color-button_alt-text);
		}
	}
}

.b-dialog-btn.m-alt .b-dialog-btn_text {
	@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
	@include t-link_underlined($state: hover);

	letter-spacing: inherit;
}


.tier-tiles {
	font-family: $font-main;
	.tierTitle {
		font: 300 20px/24px $font-main;
		letter-spacing: 0.4px;
		line-height: 32px;
	}
	.tier-descMemship {
		color: $color-quantity;
		font-family: $font-main;
		font-size: 12px;
		letter-spacing: 0.5px;
		line-height: 18px;
	}
}

.tier-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	@include media(md-down){
		align-self: flex-start;
	}

	@include media(sm) {
		display: block;
		padding: 15px;
	}
	.tier-list.b-carousel {
		align-items: flex-start;
		overflow: scroll;
		max-width: 100%;
		@include media(sm) {
			padding: 2px;
		}
	}
	.tier-tile-backdrop {
		width: 100%;
		min-width: 200px;
		max-width: 320px;
		border: 1px solid $color-quantity !important;
		background-color: $color-primary-background !important;
		margin: 20px 10px;
		position: relative;
		@include media(sm) {
			width: auto;
		}
		&.b-carousel-item {
			&:focus-within {
				border: 1px solid;
			}
		}
		.selected-pill {
			display: none;
			width: 140px;
			height: 32px;
			border-radius: 20px;
			color: $color-primary-background !important;
			background:$color-secondary-accent !important;
			border-color: $color-secondary-accent !important;
			position: absolute;
			font: 16px/24px $font-main;
			letter-spacing: 0.3px;
			top: -16px;
			padding-top: 3px;
			@include icon(checkmark-plain, $icon-width: 12px, $icon-height: 12px) {
				display: inline-block;
				margin-right: 8px;
				margin-top: 5px;
				vertical-align: text-top;
			}
			&:before {
				background-color: #fff;
			}
		}

		&.active {
			border: 1px solid $color-secondary-accent !important;
			.selected-pill {
				display: block;
				margin: auto;
			}
		}
	}

	.tier-descMemship {
		border-top: 1px solid $color-shade_4;
		padding: 16px 0;
		margin: 5px;
		text-align: left;
		font: 400 12px/18px $font-main;
		letter-spacing: 0.4px;
	}

	.tierPriceMemship {
		font: 400 16px $font-main;
		letter-spacing: 0.3px;
		margin-top: 27px;
		line-height: 24px;
	}

	.tierPercentMemship {
		font: 400 12px/18px $font-main;
	}

	.b-carousel-track {
		gap: 0;
	}

}

.tier-details {
	.tier-details-title {
		font: 600 32px/48px $font-alt;
		letter-spacing: 0.6px;
		padding-top: 64px;
		padding-bottom: 16px;
	}
	.tier-description {
		font: 400 20px/32px $font-alt;
		letter-spacing: 0.5px;
	}
}

.tier-tile-info {
	.tier-tile-details {
		.tier-tile {
			.tierQty {
				font-family: $font-main;
				letter-spacing: 0.3px;
			}
		}
	}
}

.f-input_radio-label, .f-input_checkbox-label{
	color: $color-shade_3 !important;
}

.wcmembership-infotile {
	h2{
	font-family: $font-alt;
    font-size: 32px;
    font-weight: 600;
	letter-spacing: 0.6px;
   	line-height: 46px;
	}
	p {
		font-family: $font-main;
		font-size: 20px;
  		letter-spacing: 0.4px;
  		line-height: 32px;

	}
}

.selected {
	background: transparent !important;
	color: $color-primary !important;
	border: 3px solid $color-secondary-accent;
}

.removebtn {
	background-color: $color-secondary-accent;
}

.editbtn {
	font-size: 16px;
	line-height: 24px;
	color: $color-shade_3;
}

// login form
.milkrun-login {
	.login-account-title,
	.create-account-title {
		font-family: $font-main;
		color: $color-primary;
		letter-spacing: 0.4px;
	}
}

.b-breadcrumbs-list {
	&.m-current {
		letter-spacing: 0.3px !important;
	}
}

.your-shipment {
	.heading {
		border-bottom: 1px solid $color-primary;
	}

	.shipment-card {
		border: 1px solid $color-primary;

		.skip-modal {
			.skip {
				font: 16px/24px $font-main !important;
				letter-spacing: 0.5px;
				text-transform: capitalize !important;
				color: $color-secondary-accent;
			}
		}

		.shipment-type {
			background: $color-secondary-accent;

			&.confirm {
				background-color: $color-account_nav-item__active-bg;
			}

			.title {
				font: 12px/18px $font-main !important;
				letter-spacing: 0.5px !important;
			}

			.customize {
				font: 12px/18px $font-main !important;
				letter-spacing: 0.5px !important;
			}
		}

		.title-block {
			border-bottom: 1px solid $color-shade_4;

			.title {
				font: 600 24px/40px $font-alt !important;
				letter-spacing: 0.7px;
			}

			.skip {
				font: 16px/24px $font-main !important;
				letter-spacing: 0.5px !important;
				text-transform: capitalize;
			}
		}

		.shipment-info {
			@include media(md-up) {
				border-bottom: 1px solid $color-shade_4;
			}

			.shipment-info-col {
				.reschedule-modal-link {
					.b-login_helpers-forgot_link {
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
						color: $color-secondary-accent;
					}
				}

				.info-list {
					&:nth-child(n-1) {
						margin-bottom: 8px !important;
					}

					.info-title {
						letter-spacing: 0.3px !important;
						font: 16px/24px $font-main !important;
					}

					.info-amt {
						letter-spacing: 0.3px !important;
						font: 16px/24px $font-main !important;
					}

					&.total {
						border-top: 1px solid $color-shade_4;
						padding-top: 16px;
						letter-spacing: 0.3px !important;
						font: 400 16px/24px $font-main !important;
					}
				}

				.discount {
					color: $color-primary;
				}

				.info-shipmentDate {
					font-family: $font-main !important;

					.info-amt.shipment-date-info {
						font-weight: normal !important;
					}
				}

				.shipping-info {
					.title {
						letter-spacing: 0.5px;
						margin-bottom: 8px !important;
						font: 500 12px/18px $font-main !important;
						color: $color-primary;
					}

					.description {
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
						color: $color-primary;

						&.info {
							color: $color-shade_3 !important;
							font: 12px/18px $font-main !important;
							letter-spacing: 0.4px;
						}
					}

					a {
						text-transform: capitalize !important;
						color: $color-primary;
						font: 16px/24px $font-main !important;
						letter-spacing: 0.5px !important;
					}
				}
			}
		}

		.shipping-products {
			.shipping-header {
				.title {
					font: 400 20px/32px $font-main !important;
					letter-spacing: 0.4px;
				}

				.b-account_card-button.customizecard{
					font: 16px/24px $font-main !important;
					letter-spacing: 0.5px !important;
                }
			}

			.shipping-items {
				.item {
					.item-container {
						.product-desc {
							.title {
								font: 16px/24px $font-main !important;
								letter-spacing: 0.2px !important;
								text-transform: capitalize !important;
								color: $color-primary;
							}

							.price {
								font: 16px/24px $font-main !important;
								letter-spacing: 0.3px !important;

								.m-old {
									margin-right: 8px;
									color: $color-shade_3;
								}
							}

							.qty {
								font: 16px/24px $font-main ;
								letter-spacing: 0.5px;
							}
						}
					}
				}
			}
		}
	}
}

.wineclub-modal {
	&.m-quick_view {
		.b-dialog-window {
			padding: 32px 20px;
		}
	}
}

.edit-milkRun {
	.qty-available {
		.quantity-options {
			.radio__label {
				&.selected {
					background-color: $color-secondary !important;
				}
			}
		}
	}
}

.membership-title {
	font: 20px/32px $font-main !important;
	letter-spacing: 0.4px !important;
}

.wcmembership-title {
	font: 16px/24px $font-main !important;
	letter-spacing: 0.2px !important;
}

.wineclub-title {
	color: $color-primary;
	font: 600 32px/48px $font-alt !important;
	letter-spacing: 0.6px !important;
}

.discount {
.info-title, .info-amt {
	color: $color-shade_10;
	}
}

.b-account_nav-item {
	font-family: $font-main;
	letter-spacing: 0.3px;
}

.b-product_badge {

	&.m-promo {
		background: $color-badge-2;
		color: $color-primary;
	}
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		background-image: url("./svg-icons/badge-designlogo.svg");
	}
	&-abbr, &-text {
		font-size: 12px !important;
		letter-spacing: 0.5px;
		line-height: 18px;
		margin-top: 5px;
		color:  $color-primary !important;
		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-points {
		font-size: 20px !important;
		letter-spacing: 0.5px;
		margin-top: 5px;
		color: $color-primary;
		@include media(md-down) {
			font-size: 16px !important;
		}
	}
}

.tasting-reservation {
    padding : 0;
	.tasting-reservation-col-even h2,
	.tasting-reservation-col-odd h2 {
    	color: $color-primary-accent;
	}
}

.louis-desc {
	font-family: $font-main;
	font-size: 24px;
	letter-spacing: 0.23px;
	line-height: 39px;
}

@media (max-width: 767px) {
	.louis-desc {
		padding: 0 20px;
	}
}

.b-breadcrumbs {
    &-item {
        align-items: baseline;
        &:not(.m-current) {
            @include media(md-up) {
                @include icon('arrow-right', 8px, 8px, after) {
                    background: $color-breadcrumb-separator_bg;
                    margin: 2px 15px 0;
                }
            }
        }
    }
}

.b-breadcrumbs {
    &-item {
		line-height: 24px;
    }
}

.b-categories_navigation-item_1:after{
	border-bottom: 4px solid $color-primary-accent;
}

.b-categories_navigation-link_1{
	color: $color-secondary-accent;
}

// #Brand logo responsive #
.b-logo-wrapper {
	@include media(sm){
    	padding-bottom: 45px;
	}
}

.b-logo-image {
	&.logo {
		&-desktop {
			@include media(sm){
				display: none;
			}
		}
		&-mobile {
			display: none;
			@include media(sm){
				display: block;
				width: 150px;
			}
		}
	}
}

//footer
.b-footer{
	border-top: 1px solid $color-shade_8;
	line-height: 24px;
	.b-footer-bottom_info{
		border-top:0;
	}
	.b-footer_navigation{
		&-title{
			letter-spacing: 0.3px;
			font-family: $font-main;
			font-weight: 500;
		}
		&-link{
			color:var(--color-link, $color-secondary-accent) !important;
			font: 16px/24px $font-main;
			letter-spacing: 0.3px;
		}
	}

	.b-footer_social{
		&-title{
			letter-spacing: 0.3px !important;
			font-family: $font-main;
			font-weight: 500;
			text-transform: capitalize !important;
		}
	}

	.b-footer-newsletter_title{
		@include t-heading_6;
		font: 400 16px/24px $font-main !important;
		letter-spacing: 0.3px !important;
		text-transform: capitalize !important;
	}

	.b-footer-copyright{
		opacity: initial !important;
		font-size: 12px !important;
		letter-spacing: 0.5;
		line-height: 18px;
		font-family: $font-main;
	}

	.b-footer_legal-link{
		color: var(--color-link, $color-secondary-accent);
		font: 400 12px/18px $font-main;
		letter-spacing: 0.5px;
		line-height: 18px;
	}
}


// complete membership
.welcomeMsg {
	font: 32px/48px $font-alt;
	letter-spacing: 0.5px;
}

//edit shipment dialog

.edit-shipment-dialog {
	.b-user_greeting-message {
		@media screen and (max-width: 767px) {
			white-space: normal;
		}
	}
}

.b-carousel-pagination_control {
	border: 1px solid  $color-secondary-accent;
}

.b-header {
	font-size: 16px;
	.b-header_login-title_link {
		&:before {
			min-width: 16px;
		}
		@include media(lg-up){
			min-width: 138px;
			padding: 12px;
		}
	}
	&.m-stuck {
		.b-categories_navigation {
			&-list_1 {
				@include media(lg-up) {
					padding: rh(8 0 0);
				}
			}
		}
		.b-header_login-title_link {
			min-width: 0px;
		}
		.b-header-utility_item {
				&.m-favorites {
					font-size: 0;
					min-width: 0;
			}
		}
	}
	&-utility_item {
		&.m-favorites:after,&.m-favorites_active:after{
			display: none;
		}
		&.m-favorites {
			@include icon(heart, $icon-width: 17px, $icon-height: 16px, $position: before);
			padding: 15px;
			&:before{
                margin-right: 10px;
				min-width: 16px;
            }
			@include media(lg-up){
				min-width: 138px;
			}
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: before) {
				background: $color-accent;
			}
		}
	}
}

.b-ship_to-state {
    border-bottom: 1px solid;
	color: $color-header;
}

.b-ship_to-link:after {
	height: 8px;
	width: 8px;
}

// wineclub registration form
.mr-register-form {
	.b-account_benefits {
		.b-account_benefits-title {
			letter-spacing: 0.7px;
		}
		.b-account_benefits-item {
			letter-spacing: 0.3px;
			color: $color-quantity;
		}
	}
}

svg{
	color: $color-svg-icon;
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			border: $configuration-button_main-border_width solid $color-quantity !important;
		}
	}
}

.b-product_tile {
	&.m-grid {
	.b-product_quantity {
	&.m-disabled {
		border-color: $color-shade_4 !important;
				}
			}
		}
	}
.t-paragraph_1, .t-paragraph_2, .t-paragraph_3, .t-paragraph_4{
	letter-spacing: 0.5px;
}

.b-message{
	letter-spacing: 0.5px;
	&-link{
		letter-spacing: 0.5px;
	}
}

.b-carousel-slide {
	.b-product_tile-quantity {
		.b-product_quantity {
		margin-right: 8px;
		}
	}
}

.b-carousel {
	&-control{
		border: 0;
		--icon-color: var(--carousel-control-color, #{$color-carousel_control});
		&[disabled] {
			--icon-color: var(--carousel-control-color, #{$color-carousel_control__disabled});
			opacity: initial;
			}
	}
	&-pagination_control{
		border: 1px solid $color-secondary;
	}
}

.b-product_quantity {
	.b-product_quantity-label {
	&.m-minus:before {
     background: $color-shade_4;
			}
		}
	.b-product_quantity-input {
		color: $color-secondary-accent;
		font-family: $font-main;
		font-size: 20px;
		letter-spacing: 0.4px;
		line-height: 32px;
	}
}

.b-product_gallery-thumb.m-current:after{
    border-color: $color-secondary-accent !important;
}


.b-ship_to-link, .b-header-navigation_link.m-stores{
	color: $color-accent;
}